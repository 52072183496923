window.addEventListener('scroll', function(event) {

    var scrollY = window.scrollY,
        winHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    var sections = document.querySelectorAll('.js-section');
    Array.prototype.forEach.call(sections, function(section, i) {

        var id = section.getAttribute('id'),
            inview = parseInt(section.getAttribute('data-inview') ? section.getAttribute('data-inview') : 1),
            bounding = section.getBoundingClientRect(),
            styles = window.getComputedStyle(section),
            margins = Math.round(parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom'])),
            bottom = Math.round(bounding.bottom + margins);

        if ( bottom > 0 && bounding.top < winHeight ) { // The section is visible in the viewport

            var sectionTopBreak = Math.round((winHeight - bounding.top) / winHeight * 100),
                sectionBottomBreak = Math.round(bottom / winHeight * 100),
                visiblePercent = 0;

            if ( sectionTopBreak < 100 ) {
                visiblePercent = sectionTopBreak;
            } else if ( sectionBottomBreak < 100 ) {
                visiblePercent = sectionBottomBreak;
            } else {
                visiblePercent = 100;
            }

            if ( visiblePercent >= inview ) {
                section.classList.add("is-visible");
                if ( id )
                    document.body.classList.add('is-section--'+id);
            } else {
                section.classList.remove("is-visible");
                if ( id )
                    document.body.classList.remove('is-section--'+id);
            }

        } else if ( section.classList.contains("is-visible") )  {
            section.classList.remove("is-visible");
        }
    });

}, { passive: true });
var event = new Event('scroll');
window.dispatchEvent(event);
