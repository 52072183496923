window.addEventListener("load", function() {
    document.body.classList.add("is-loaded");
});

window.addEventListener("beforeunload", function(ev) {
    document.body.classList.remove("is-loaded");
});

var toggleStateMenu = document.querySelector(".js-toggleStateMenu");

if (toggleStateMenu) {
    toggleStateMenu.addEventListener("click", function() {
        document.body.classList.toggle("is-state--menu");
    });
}
